<script setup lang="ts">
interface CollapsableProps {
  icon: string
  title: string
  body: string
  blocked?: boolean
  verified?: boolean
}

const props = defineProps<{ collapse: CollapsableProps; isStatic: boolean }>()

const isOpen = props.isStatic ? ref(true) : ref(false)

const blockedFieldIcon = '/images/icons/collapsable/lock.svg'
const collapseIcon = computed(() => {
  if (!isOpen.value) return '/images/icons/arrowDown.svg'
  else return '/images/icons/arrowForward.svg'
})
const verifiedIcon = computed(() => {
  if (props.collapse.verified === undefined) return undefined
  return props.collapse.verified ? 'success' : 'warning'
})
const changeVisibility = () => !props.isStatic && (isOpen.value = !isOpen.value)
</script>

<template>
  <main class="flex h-14 items-center gap-5">
    <figure class="flex w-8 min-w-[2rem] flex-row items-center" :class="{ 'h-10': verifiedIcon }">
      <img class="h-6 w-6" :src="props.collapse.icon" :alt="props.collapse.title + ' icon'" />
      <!-- Verified or not verified icon -->
      <img
        v-if="verifiedIcon"
        class="h-[0.85rem] w-[0.85rem] self-end"
        :src="`/images/icons/toast/${verifiedIcon}.svg`"
        :alt="props.collapse.title + ' verification'"
      />
    </figure>

    <aside v-if="props.collapse.blocked" class="flex w-full justify-between self-center">
      <section class="flex flex-col">
        <h4 class="text-sm text-mcd-black">{{ props.collapse.title }}</h4>
        <h4 class="text-xs text-mcd-secondaryDarkGrey">{{ props.collapse.body }}</h4>
      </section>
      <figure>
        <img class="h-full w-4" :src="blockedFieldIcon" alt="lock" />
      </figure>
    </aside>

    <aside v-else class="flex w-full justify-between self-center" @click="changeVisibility">
      <section>
        <h4 :class="isOpen && 'text-sm text-mcd-black'">{{ props.collapse.title }}</h4>
        <Transition name="slide-fade" appear>
          <div v-if="isOpen" class="max-h-[100vh] opacity-100">
            <h4 class="text-xs text-mcd-secondaryDarkGrey">{{ props.collapse.body }}</h4>
          </div>
        </Transition>
      </section>
      <figure>
        <img class="h-full w-6" :src="collapseIcon" alt="collapse" />
      </figure>
    </aside>
  </main>
</template>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
